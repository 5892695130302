<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click="save(true)">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn :disabled="loading" text small @click="save(false)">
            <v-icon>mdi-content-save-move</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text class="pt-5">
            <v-combobox
                v-if="record.parent_id"
                :items="children_assets"
                v-model="record.name"
                @update:search-input="updateDescription"
                :label="$store.getters.translate('name')"
                item-text="name"
                item-value="name"
                :return-object="false"/>
            <v-text-field
                v-else
                :label="$store.getters.translate('name')"
                v-model="record.name"
                required
                validate-on-blur/>
            <v-text-field
                v-if="!record.parent_id"
                :label="$store.getters.translate('platformName')"
                v-model="record.platformName"/>
            <v-text-field
                :label="$store.getters.translate('description')"
                v-model="record.description"/>
            <base-tags v-model="record.tags" :modal_name="page.name"/>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["modal_name"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("asset"),
        name: "assets",
        model: "asset"
      },
      loading: false,
      record: {},
      children_assets: [],
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(parent_id = null, tags = []) {
      this.record = {
        parent_id: parent_id,
        tags: tags
      };
      this.openModal(this.modal_name);
      if(this.record.parent_id) {
        this.loadChildrenAssets();
      }
    },
    async save(close) {
      if(!this.loading) {
        this.loading = true;
        if(await helpFunctions.createModel(this.$toasted, this.page.name, this.record)) {
          this.$emit("refresh");
          if (close) {
            this.reset();
          } else {
            this.record.id = null;
            this.record.name = "";
            this.record.description = "";
          }
        }
        this.loading = false;
      }
    },
    loadChildrenAssets() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/assets/get-children", { parent_id: this.record.parent_id })
          .then((response) => {
            this.loading = false;
            this.children_assets = response.data;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    updateDescription(currentValue) {
      this.record.name = currentValue;
      let temp = this.children_assets.filter((children_asset) => children_asset.name === this.record.name);
      if(temp.length > 0) {
        this.record.id = temp[0].id;
        this.record.name = temp[0].name;
        this.record.description = temp[0].description;
      }
      else {
        this.record.id = null;
        this.record.description = "";
      }
    },
    reset() {
      this.record = {};
      this.closeModal(this.modal_name);
    },
  },
};
</script>