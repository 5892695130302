<template>
  <v-card :disabled="loading" :loading="loading">
    <v-toolbar dense flat>
      <v-toolbar-title class="mr-4">
        <v-text-field
          v-model="search"
          v-debounce:800ms.lock="launchSearch"
          @click:clear="clearSearch"
          :label="$store.getters.translate('search_in_assets')"
          single-line
          hide-details
          clearable
          append-icon="mdi-magnify"/>
      </v-toolbar-title>
      <v-toolbar-title class="mr-4">
        <v-text-field
            v-model="searchInDocuments"
            v-debounce:800ms.lock="launchSearchInDocuments"
            @click:clear="clearSearchInDocuments"
            :label="$store.getters.translate('search_in_documents')"
            single-line
            hide-details
            clearable
            append-icon="mdi-magnify"/>
      </v-toolbar-title>
      <v-spacer/>
      <small class="secondary--text" v-if="loading_counters">
        <v-progress-circular :size="15" color="secondary" indeterminate width="2" class="mr-1"/>
        {{ $store.getters.translate("loading_stats") }}
      </small>
      <template v-if="selected_assets.length > 0">
        <v-btn v-if="selected_assets_move.length > 0" color="primary" @click.stop="bulkMove()" text small>
          <span>
            {{ $store.getters.translate("move") }}
            {{ selected_assets_move.length }}
            {{ $store.getters.translate("assets") }}
          </span>
        </v-btn>
        <v-btn v-if="selected_assets_copy.length > 0" color="primary" @click.stop="bulkCopy()" text small>
          <span>
            {{ $store.getters.translate("copy") }}
            {{ selected_assets_copy.length }}
            {{ $store.getters.translate("assets") }}
          </span>
        </v-btn>
        <v-btn v-if="selected_assets_delete.length > 0" color="red" @click.stop="bulkArchiveOrDetach()" text small>
          <span>
            {{ $store.getters.translate("delete") }}
            {{ selected_assets_delete.length }}
            {{ $store.getters.translate("assets") }}
          </span>
        </v-btn>
        <v-divider v-if="module_name === 'platforms'" class="mr-2 ml-2" vertical/>
      </template>
      <v-btn v-if="module_name === 'platforms' && canCreatePlatform(0)" @click="createAsset(null)" text small color="primary">
        {{ $store.getters.translate("create_platform") }}
        <v-icon right small>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider/>
    <v-card-text>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-autocomplete
                v-if="module_name === 'platforms'"
                :label="$store.getters.translate('select_platforms')"
                v-model="selected_platforms"
                :items="platforms"
                chips
                deletable-chips
                small-chips
                multiple
                item-value="id"
                item-text="name"
                :return-object="false"
                outlined
                dense
                hide-details
                clearable
                class="mb-5"/>
              <base-tags v-model="tags" :available_tags="available_tags" modal_name="platforms"/>
            </v-col>
          </v-row>
          <v-treeview
            item-disabled="locked"
            class="mt-5"
            :items="assets"
            item-key="id"
            item-text="name"
            :open.sync="open_assets"
            :active.sync="active_assets"
            activatable
            color="success"
            dense
            transition
            :key="key"
            hoverable
            selection-type="independent"
            v-model="selected_assets"
            selectable>
            <template v-slot:label="{ item }">
              <!--<div @click="setActiveAssetId(item.id || 0)">-->
                <draggable
                  :disabled="item.locked"
                  style="display: contents"
                  :list="assets"
                  group="node"
                  :id="item.id"
                  :data-parent="item.parent_id"
                  :force-fallback="true"
                  :scroll-sensitivity="200"
                  @end="endDrag"
                  @hover="openAsset">
                  <v-icon @click="item" left>mdi-drag-vertical</v-icon>
                </draggable>
                <span v-if="item.locked" @click="setActiveAssetId(item.id || 0)">{{ item.name }}</span>
                <span v-else>{{ item.name }}</span>
              <!--</div>-->
            </template>
            <template v-slot:append="{ item }">
              <v-btn v-if="+item.id !== 0 && canDelete(item.id || 0) && !item.locked" @click="deleteAsset(item)" icon dark small color="secondary">
                <v-icon small color="error">mdi-trash-can</v-icon>
              </v-btn>
              <v-btn
                  v-if="+item.id !== 0 && canCreate(+item.id) && !item.locked" @click="createAsset(item)" icon dark small color="secondary">
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                  v-if="+item.id !== 0 && canCreatePermission()"
                  @click="showPermissionManager(item)"
                  icon
                  dark
                  small
                  color="secondary"
                  :class="[isCreatePermissionDisabled?'custom-disabled' : '']">
                <v-icon small>mdi-lock-outline</v-icon>
              </v-btn>
            </template>
          </v-treeview>
        </v-col>
        <v-divider v-if="!$vuetify.breakpoint.smAndDown" vertical/>
        <v-col :cols="!$vuetify.breakpoint.smAndDown ? 5 : 12">
          <template v-if="selected_asset">
            <div class="sticky" style="overflow: auto;height: 90vh;">
              <v-card flat>
                <v-toolbar dense flat>
                  {{ $store.getters.translate("asset") }}
                  <v-spacer/>
                  <v-btn v-if="canEdit(selected_asset.id || 0)" @click="saveAsset()" text small color="primary">
                    {{ $store.getters.translate("save") }}
                    <v-icon right small>mdi-content-save</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    dense
                    outlined
                    v-model="selected_asset.name"
                    :label="$store.getters.translate('name')"/>
                  <v-text-field
                    dense
                    outlined
                    v-model="selected_asset.description"
                    :label="$store.getters.translate('description')"/>
                  <base-log-field :model_type="'Asset'" :model_id="selected_asset.id"/>
                  <base-tags v-model="selected_asset.tags" modal_name="assets"/>
                  <v-autocomplete
                      v-if="$store.getters.getUser.superuser && !selected_asset.parent_id"
                      :label="$store.getters.translate('teamleads')"
                      v-model="selected_asset.employees"
                      :items="employees"
                      chips
                      deletable-chips
                      small-chips
                      multiple
                      item-value="id"
                      item-text="name"
                      outlined
                      dense
                      hide-details
                      clearable
                      :return-object="true"
                      class="mb-5 mt-5"/>
                  <template v-if="$store.getters.getUser.id === 1 || $store.getters.getUser.email === 'p.koop@qts.nl'">
                    <v-autocomplete
                        :label="$store.getters.translate('books')"
                        v-model="selected_book"
                        item-value="id"
                        item-text="name"
                        clearable
                        :return-object="true"
                        :items="books"
                        class="mb-5 mt-5"/>
                    <v-autocomplete
                        v-if="selected_book"
                        :label="$store.getters.translate('chapters')"
                        v-model="selected_chapter"
                        item-value="id"
                        item-text="name"
                        clearable
                        :return-object="true"
                        :items="selected_book.content"
                        class="mb-5 mt-5"/>
                    <v-autocomplete
                        v-if="selected_chapter && selected_chapter.type === 'chapter'"
                        :label="$store.getters.translate('pages')"
                        v-model="selected_page"
                        item-value="id"
                        item-text="name"
                        clearable
                        :return-object="true"
                        :items="selected_chapter.pages"
                        class="mb-5 mt-5"/>
                    <v-btn v-if="(selected_chapter && selected_chapter.type === 'page') || selected_page" @click.stop="viewDocumentation" block color="primary">
                      {{ $store.getters.translate("documentation") }}
                    </v-btn>
                  </template>
                </v-card-text>
              </v-card>
              <v-divider/>
              <v-toolbar dense flat>
                {{ $store.getters.translate("documents") }}
                <v-spacer/>
                <v-btn v-if="canEdit(+selected_asset.id) || canCreateDocument(0)" @click="createDocument()" text small color="primary">
                  {{ $store.getters.translate("create") }}
                  <v-icon right small>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>
              <!--<v-list class="sticky" dense>-->
              <v-list dense v-if="selected_asset.documents.length > 0">
                <template v-for="(type_value, type_name, type_index) in types">
                  <v-list-group :key="'type_'+type_index" sub-group no-action eager style="border-bottom: 1px solid #9e9e9e !important">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title v-text="$lodash.truncate(type_name, { length: 45 })"/>
                      </v-list-item-content>
                    </template>
                    <template v-for="(subtype, subtype_index) in type_value">
                      <v-list-group :key="'subtype_'+subtype_index" sub-group no-action eager style="padding-left: 24px; border-bottom: 1px solid #9e9e9e !important">
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title v-if="subtype == ''" v-text="$lodash.truncate($store.getters.translate('documents_without_subtype'), { length: 45 })"/>
                            <v-list-item-title v-else v-text="$lodash.truncate(subtype, { length: 45 })"/>
                          </v-list-item-content>
                          <v-list-item-action right>
                            <v-btn  @click.stop="downloadFiles(type_name, subtype)" block right text small color="primary">
                              {{ $store.getters.translate("download") }}
                            </v-btn>
                          </v-list-item-action>
                        </template>
                        <template v-for="(document, index) in selected_asset.documents">
                          <template v-if="!document.hidden && document.type === type_name && document.subtype === subtype">
                            <!-- eslint-disable-next-line vue/valid-v-for -->
                            <v-list-group :key="index" sub-group no-action eager style="padding-left: 24px; border-bottom: 1px solid #9e9e9e !important">
                              <template v-slot:activator>
                                <v-list-item-action v-if="canShowAsset(selected_asset.id) || canViewDocument(document.id)">
                                  <v-btn @click.stop="preview(document)" x-small icon color="primary">
                                    <v-icon>mdi-eye</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title v-if="document.revision_name && document.revision_name !== 'null'" v-text="document.revision_name + '/' + $lodash.truncate(document.name, { length: 35 })"/>
                                  <v-list-item-title v-else v-text="$lodash.truncate(document.name, { length: 35 })"/>
                                  <v-list-item-subtitle v-if="document.description && document.description !== 'null'">
                                    {{ $lodash.truncate(document.description, { length: 40 }) }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action right v-if="document.commissioned">
                                  <v-chip x-small label color="success">
                                    commissioned
                                  </v-chip>
                                </v-list-item-action>
                                <v-list-item-action v-if="canEdit(selected_asset.id) || canDeleteDocument(document.id)">
                                  <v-btn text small @click.stop="deleteDocument(document)">
                                    <v-icon small color="error">mdi-trash-can</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </template>
                              <template v-for="(revision) in allRevisions(document)">
                                <v-list-item :key="revision.id" color="primary">
                                  <v-list-item-action v-if="canShowAsset(selected_asset.id) || canViewDocument(revision.id)">
                                    <v-btn x-small icon @click.stop="preview(revision)" color="primary">
                                      <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ $lodash.truncate(revision.revision_name, { length: 35 }) }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ moment(revision.created_at).format("DD-MM-YYYY HH:mm") }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action v-if="revision.commissioned">
                                    <v-chip x-small label color="success">
                                      commissioned
                                    </v-chip>
                                  </v-list-item-action>
                                  <v-list-item-action v-if="canEdit(selected_asset.id) || canDeleteDocument(revision.id)">
                                    <v-btn text small @click.stop="deleteDocument(revision)">
                                      <v-icon small color="error">mdi-trash-can</v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>
                              </template>
                              <v-list-item-action v-if="canCreateDocument(0)">
                                <v-btn @click="createRevision(document)" :key="document.id" block right text small color="primary">
                                  {{ $store.getters.translate("create_revision") }}
                                  <v-icon right small>mdi-plus</v-icon>
                                </v-btn>
                              </v-list-item-action>
                              <v-divider/>
                            </v-list-group>
                          </template>
                        </template>
                      </v-list-group>
                    </template>
                  </v-list-group>
                </template>
              </v-list>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
    <create-asset modal_name="create_asset" ref="create_asset" @refresh="updatePermissions(load)"/>
    <create-document modal_name="create_document" ref="create_document" @refresh="load(); getAssetInfo()"/>
    <document-preview modal_name="document_preview" ref="document_preview" @refresh="load(); getAssetInfo()" :asset_id="active_asset_id" @create="createRevision"/>
    <base-link-asset modal_name="link_asset_move" ref="link_asset_move" class="mb-3" @refresh="processBulkMove" :module_name="module_name"/>
    <base-link-asset modal_name="link_asset_copy" ref="link_asset_copy" class="mb-3" @refresh="processBulkCopy" :module_name="module_name" action="copy"/>
    <manage-by-roles ref="custom_permission_manager_asset_panel" :selected_record="selected_record" @disableManaging="manageCreatePermissionDisabledStatus" @refresh="launchSearch()"/>
    <base-documentation modal_name="documentation" ref="documentation" :selected_chapter="selected_chapter" :selected_page="selected_page"/>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import DocumentPreview from "./DocumentPreview.vue";
import lodash from "lodash";
import CreateDocument from "../../views/documents/Create";
import CreateAsset from "../../views/assets/Create";
import helpFunctions from "../../plugins/helpFunctions.js";
import BaseLogField from "./BaseLogField";
import BaseLinkAsset from "./BaseLinkAsset";
import ManageByRoles from "../../views/permissions/ManageByRoles";
import defineRulesFor from "../../ability";
import BaseDocumentation from "./BaseDocumentation";

export default {
  components: {
    draggable,
    CreateDocument,
    DocumentPreview,
    CreateAsset,
    BaseLogField,
    BaseLinkAsset,
    ManageByRoles,
    BaseDocumentation,
  },
  props: ["module_name", "editable"],
  data() {
    return {
      isCreatePermissionDisabled: false,
      selected_record: null,
      all_assets: [],
      all_assets_names: {},
      assets: [],
      platform_id: null,
      active_assets: [],
      open_assets: null,
      key: 0,
      loading: false,
      search: "",
      searchInDocuments: "",
      searchAssets: [],
      assets_tags: null,
      tags: null,
      project_tags: [],
      available_tags: [],
      selected_asset: null,
      books: [],
      selected_book: null,
      selected_chapter: null,
      selected_page: null,
      employees: [],
      active_asset_id: null,
      assets_limit: 10,
      selected_assets: [],
      selected_assets_move: [],
      selected_assets_copy: [],
      selected_assets_delete: [],
      platforms: [],
      child_open_assets: null,
      child_active_assets: null,
      selected_platforms: [],
      platform_type: 'parent',
      loading_counters: false,
      processed_platforms: 0,
      types: {}
    };
  },
  created() {
    if (this.module_name === "platforms") {
      this.getPlatforms();
    }
    if(this.$store.getters.getUser.superuser) {
      this.getEmployees();
    }
    if(this.$store.getters.getUser.id === 1 || this.$store.getters.getUser.id === 152) {
      this.getBooks();
    }
  },
  methods: {
    setPlatformId(id, type) {
      this.platform_type = type;
      if(this.platform_type === 'all-platforms') {
        if(helpFunctions.getLocalStorageValue(this.module_name + "_selected_platforms")) {
          this.selected_platforms = helpFunctions.getLocalStorageValue(this.module_name + "_selected_platforms");
        }
        else {
          this.load();
        }
      }
      else {
        if (this.platform_type === 'child') {
          this.child_active_assets = [id];
        }
        this.selected_platforms = [id];
      }
    },
    load() {
      this.all_assets = [];
      if (this.platform_type === 'child') {
        this.getParentPlatformID();
      }
      else if (this.selected_platforms.length > 0) {
        clearTimeout(this._timerId);
        this._timerId = setTimeout(() => {
          this.assets_tags = null;
          this.available_tags = [];
          this.processed_platforms = this.selected_platforms.length;
          for (let i = 0; i < this.selected_platforms.length; i++) {
            this.loadDocumentAmountAndTags(this.selected_platforms[i]);
          }
          this.loadSelectedPlatforms();
        }, 1000);
      }
      else if (this.loading === false) {
        clearTimeout(this._timerId);
        this.assets_tags = null;
        this.available_tags = [];
        this.allPlatforms();
        for (let i = 0; i < this.assets_limit; i++) {
          this.platformNames(i);
        }
      }
    },
    async getPlatforms() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/assets?root=true")
        .then((response) => {
          this.platforms = response.data;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    async getEmployees() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/employees")
          .then((response) => {
            this.employees = response.data.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async allPlatforms() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/assets")
        .then((response) => {
          this.all_assets = response.data;
          if (this.assets_limit === 0) {
            this.loading = false;
            this.assets_limit = 10;
            this.combineIDsWithNames(this.all_assets);
            this.all_assets_names = {};
            this.all_assets = this.$lodash.sortBy(this.all_assets, "name");
            if(this.platform_type === 'all-platforms') {
              this.tags = helpFunctions.getLocalStorageValue(this.module_name + "_tags");
              this.open_assets = helpFunctions.getLocalStorageValue(this.module_name + "_open_assets");
              this.active_assets = helpFunctions.getLocalStorageValue(this.module_name + "_active_assets");
            }
            this.showRecords();
          }
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    manageCreatePermissionDisabledStatus(isDisabled = true){
      this.isCreatePermissionDisabled = isDisabled;
    },
    canCreatePermission(id = 0) {
      return this.$can("create", this.$createEntity('CustomPermission', {id}));
    },
    canShowAsset(id = 0) {
      return this.$can("show", this.$createEntity('asset', {id}));
    },
    showPermissionManager(record) {
      this.$refs.custom_permission_manager_asset_panel.managerOptions = {
        entityId: record.id,
        entityModel: 'asset',
        disabled: [
          'entityItems',
          'entities',
        ]
      }

      this.$refs.custom_permission_manager_asset_panel.page.title = this.$store.getters.translate("manage_custom_permissions");
      this.$refs.custom_permission_manager_asset_panel.show = true;
    },
    async platformNames(limit) {
      let start = limit * 5000;
      this.loading = true;
      this.$http
        .post(this.$store.getters.appUrl + "v2/assets/names", { start: start })
        .then((response) => {
          this.all_assets_names = {
            ...this.all_assets_names,
            ...response.data,
          };
          this.assets_limit--;
          if (this.assets_limit === 0 && this.all_assets.length > 0) {
            this.loading = false;
            this.assets_limit = 10;
            this.combineIDsWithNames(this.all_assets);
            this.all_assets_names = {};
            this.all_assets = this.$lodash.sortBy(this.all_assets, "name");
            if(this.platform_type === 'all-platforms') {
              this.tags = helpFunctions.getLocalStorageValue(this.module_name + "_tags");
              this.open_assets = helpFunctions.getLocalStorageValue(this.module_name + "_open_assets");
              this.active_assets = helpFunctions.getLocalStorageValue(this.module_name + "_active_assets");
            }
            this.showRecords();
          }
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    async loadSelectedPlatforms() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/assets" + "?selected_platforms=" + this.selected_platforms)
        .then((response) => {
          this.loading = false;
          this.all_assets = this.$lodash.sortBy(response.data, "name");
          //SET OPEN AND ACTIVE ASSETS ON FIRST LOAD
          if(!this.open_assets) {
            if(this.platform_type === 'all-platforms') {
              this.tags = helpFunctions.getLocalStorageValue(this.module_name + "_tags");
              this.open_assets = helpFunctions.getLocalStorageValue(this.module_name + "_open_assets");
              this.active_assets = helpFunctions.getLocalStorageValue(this.module_name + "_active_assets");
            }
            else if(this.child_active_assets) {
              this.open_assets = this.child_open_assets;
              this.active_assets = this.child_active_assets;
              this.child_open_assets = null;
              this.child_active_assets = null;
            }
            else {
              this.open_assets = this.selected_platforms;
              this.active_assets = this.selected_platforms;
            }
          }
          if (this.assets_tags) {
            this.addDocTagsToAssets(this.all_assets);
          }
          this.showRecords();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    getParentPlatformID() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/assets/get-parent-id", { selected_platforms: this.selected_platforms })
          .then((response) => {
            this.loading = false;
            this.platform_type = 'parent';
            this.child_open_assets = response.data.open_assets;
            this.selected_platforms[0] = response.data.parent_id;
            this.load();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async loadDocumentAmountAndTags(selected_platform) {
      if(selected_platform) {
        this.loading_counters = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/assets/count-and-tags", { selected_platform: selected_platform })
            .then((response) => {
              this.assets_tags = {...this.assets_tags, ...response.data.assets_tags};
              this.available_tags = [...this.available_tags, ...Object.keys(response.data.available_tags)];
              this.available_tags = [...new Set(this.available_tags)];
              this.processed_platforms--;
              if (this.processed_platforms === 0) {
                this.loading_counters = false;
              }
              if (this.all_assets.length > 0) {
                this.addDocTagsToAssets(this.all_assets);
                this.showRecords();
              }
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
      }
    },
    openAsset: function (event) {
      if(!this.open_assets) {
        this.open_assets = [];
      }
      this.open_assets.push(event.to.id);
    },
    saveAsset() {
      this.loading = true;
      this.$http
        .patch(this.$store.getters.appUrl + "v2/assets/" + this.selected_asset.id, this.selected_asset)
        .then(() => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("successfully_saved"));
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
      if(this.$store.getters.getUser.id === 1 || this.$store.getters.getUser.id === 152) {
        this.savePage();
      }
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity('asset', {id}));
    },
    canCreate(id = 0) {
      return this.$can("create", this.$createEntity('asset', {id}));
    },
    canCreatePlatform(id = 0) {
      return this.$can("create", this.$createEntity('platform', {id}));
    },
    canCreateDocument(id = 0) {
      return this.$can("create", this.$createEntity('document', {id}));
    },
    canDeleteDocument(id = 0) {
      return this.$can("delete", this.$createEntity('document', {id}));
    },
    canViewDocument(id = 0) {
      return this.$can("show", this.$createEntity('document', {id}));
    },
    canDelete(id = 0) {
      return this.$can("delete", this.$createEntity('asset', {id}));
    },
    canEditPlatform(id = 0) {
      return this.$can("edit", this.$createEntity('platform', {id}));
    },
    deleteAsset(item) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
              .delete(this.$store.getters.appUrl + "v2/assets/" + item.id)
              .then(() => {
                this.loading = false;
                this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
                this.selected_asset = null;
                this.active_asset_id = null;
                this.active_assets = null;
                if (this.open_assets.includes(item.id)) {
                  this.open_assets.splice(this.open_assets.indexOf(item.id), 1);
                }
                this.load();
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
        }
      });
    },
    clearSearch() {
      this.search = "";
      this.showRecords();
    },
    clearSearchInDocuments() {
      this.searchInDocuments = "";
      this.searchAssets = [];
      this.showRecords();
    },
    launchSearch() {
      this.showRecords();
    },
    launchSearchInDocuments() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/documents/assets", { search: this.searchInDocuments })
          .then((response) => {
            this.loading = false;
            this.searchAssets = response.data;
            this.showRecords();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    combineIDsWithNames(items) {
      items.forEach((item) => {
        item.name = this.all_assets_names[item.id]["name"];
        if (item.children) {
          this.combineIDsWithNames(item.children);
        }
      });
    },
    addDocTagsToAssets(items) {
      items.forEach((item) => {
        if (this.assets_tags[item.id]) {
          item.tags = [...item.tags, ...this.assets_tags[item.id]];
        }
        if (item.children) {
          this.addDocTagsToAssets(item.children);
        }
      });
    },
    copy(o) {
      return this.$lodash.cloneDeep(o);
    },
    showRecords() {
      if (this.all_assets.length > 0) {
        this.assets = lodash.cloneDeep(this.all_assets);
        if (this.tags && this.tags.length > 0) {
          this.assets = helpFunctions.sortByTags(this.assets, this.tags);
        }
        if (this.search != "") {
          let response = helpFunctions.customSearch(this.assets, this.search);
          this.assets = response[0];
          this.open_assets = response[1];
        }
        if (this.searchInDocuments != "") {
          if (this.searchAssets.length > 0) {
            let response = helpFunctions.customSearchByID(this.assets, this.searchAssets);
            this.assets = response[0];
            this.open_assets = response[1];
          }
          else {
            this.assets = [];
            this.open_assets = [];
          }
        }
        this.assets = this.recursiveCheckShow(this.assets);

        this.assets = this.recursiveCheckCreateEditDelete(this.assets);

        this.assets = helpFunctions.customSorting(this.assets);

        if(this.assets.length > 0 && (!this.active_assets || this.active_assets.length === 0)) {
          this.active_assets = [this.assets[0].id];
        }
      }
    },
    setActiveAssetId(id = 0) {
      if(this.active_assets.includes(id)) {
        this.active_assets = this.active_assets.filter(itemId => {
          return +itemId !== +id;
        })
      } else {
        this.active_assets.push(id);
      }
    },
    recursiveCheckShow(assets) {
      return assets
        .filter(asset => !!asset)
        .map(asset => {
          if (asset.children) {
            asset.children = this.recursiveCheckShow(asset.children);
          }
          const allowedByChildren = asset.children && asset.children.length;
          return (this.canShowAsset(asset.id) || allowedByChildren) ? asset : null;
        })
        .filter(asset => !!asset)
    },
    recursiveCheckCreateEditDelete(assets, locked = false) {
      return assets.map(asset => {
        if(locked) {
          asset.locked = true;
        } else if(this.editable !== null) {
          asset.locked = !this.editable;
        }
        if(!!asset.locked === false) {
          asset.locked = !(this.canDelete(asset.id) || this.canEdit(asset.id) || this.canCreate(asset.id));
        }
        if (asset.children) {
          asset.children = this.recursiveCheckCreateEditDelete(asset.children, asset.locked);
        }
        return asset;
      })
    },
    endDrag: function (event) {
      this.loading = true;
      let payload = {
        ids: [parseInt(event.srcElement.id)],
        to_id: parseInt(event.to.id),
        from_id: parseInt(event.from.dataset.parent),
      };
      if (payload.to_id == payload.from_id) {
        return;
      }
      this.open_assets.push(payload.to_id);
      this.$http
        .post(this.$store.getters.appUrl + "v2/assets/move", payload)
        .then(() => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("asset_was_moved"));
          this.load();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    createDocument() {
      this.$refs.create_document.load(null, this.selected_asset, null);
    },
    deleteDocument(document) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
            if (res) {
              this.loading = true;
              this.$http
                  .delete(this.$store.getters.appUrl + "v2/documents/" + document.id)
                  .then(() => {
                    this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
                    this.loading = false;
                    this.show = false;
                    this.load();
                    this.getAssetInfo();
                  }).catch((error) => {
                    if (error.response.status == 404) {
                      this.message = this.$store.getters.translate("nothing_found");
                      this.not_found = true;
                    }
                    this.loading = false;
                  });
            }
      });
    },
    downloadFiles(type_name, subtype) {
      this.selected_asset.documents.forEach((document) => {
        if(document.type === type_name && document.subtype === subtype) {
          this.download(document.file);
        }
      });
    },
    download(file) {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/files/" + file.id, {
          responseType: "blob",
        }).then((response) => {
          this.loading = false;
          const blob = new Blob([response.data], { type: file.content_type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    preview(document) {
      this.$refs.document_preview.load(document.id);
    },
    createRevision(document) {
      this.$refs.create_document.load(document, null, null);
    },
    allRevisions(document) {
      let revisions = [];
      //revisions.push(document);
      document.revisions.forEach((revision) => {
        revisions.push(revision);
      });
      return revisions;
    },
    async getAssetInfo() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/assets/" + this.active_asset_id)
        .then((response) => {
          this.selected_asset = response.data;
          this.types = {};
          this.selected_asset.documents.forEach((document) => {
            if(this.module_name === 'projects') {
              var document_hidden = true;
              document.tags.forEach((tag) => {
                if (this.project_tags.includes(tag)) {
                  document_hidden = false;
                }
              });
              if(document_hidden) {
                document.hidden = true;
              }
            }
            if(!document.hidden) {
              if (!this.types[document.type]) {
                this.types[document.type] = [];
              }
              if (!this.types[document.type].includes(document.subtype)) {
                this.types[document.type].push(document.subtype);
              }
              /*
              document.tags.forEach((tag) => {
                if (!this.selected_asset.tags.includes(tag)) {
                  this.selected_asset.tags.push(tag);
                }
              });
              */
            }
          });
          if(this.$store.getters.getUser.id === 1 || this.$store.getters.getUser.id === 152) {
            this.getAssetPage();
          }
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    updatePermissions(refresh = null) {
      this.$http
          .get(this.$store.getters.appUrl + "v2/abilities")
          .then((response) => {
            this.ability.update(defineRulesFor(response.data));
            if(refresh) {
              refresh();
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    bulkMove() {
      this.$refs.link_asset_move.load();
    },
    processBulkMove(ids) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
            if (res) {
              this.loading = true;
              let payload = {
                ids: this.selected_assets_move,
                to_id: ids[0],
              };
              this.open_assets.push(payload.to_id);
              this.$http
                  .post(this.$store.getters.appUrl + "v2/assets/move", payload)
                  .then(() => {
                    this.loading = false;
                    this.bulkRemoveSelectedAssets(this.selected_assets_move);
                    this.active_assets[0] = payload.to_id;
                    this.$toasted.success(this.$store.getters.translate("asset_was_moved"));
                    this.updatePermissions(this.load);
                  }).catch(() => {
                    this.$toasted.error(this.$store.getters.translate("error"));
                    this.loading = false;
                  });
            }
      });
    },
    bulkCopy() {
      this.$refs.link_asset_copy.load();
    },
    processBulkCopy(result) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          let payload = {
            ids: this.selected_assets_copy,
            to_id: result.ids,
            copy_tags: result.copy_tags,
            copy_documents: result.copy_documents
          };
          this.$http
              .post(this.$store.getters.appUrl + "v2/assets/copy", payload)
              .then(() => {
                this.loading = false;
                this.bulkRemoveSelectedAssets(this.selected_assets_copy);
                this.$toasted.success(this.$store.getters.translate("asset_was_copied"));
                this.updatePermissions(this.load);
              }).catch(() => {
                this.$toasted.error(this.$store.getters.translate("error"));
                this.loading = false;
              });
        }
      });
    },
    bulkArchiveOrDetach() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          let result = {
            model: "Asset",
            model_id: this.parent_module_id,
            relation_name: this.$relations[this.relation_name]
              ? this.$relations[this.relation_name]
              : this.relation_name,
            ids: this.selected_assets_delete,
          };
          this.$http
            .post(this.$store.getters.appUrl + "v2/bulk/remove", result)
            .then(() => {
              this.loading = false;
              this.bulkRemoveSelectedAssets(this.selected_assets_delete);
              this.$toasted.success(this.$store.getters.translate("success"));
              this.$emit("change");
              this.updatePermissions(this.load);
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
        }
      });
    },
    createAsset(item) {
      if(item) {
        if(this.project_tags.length > 0) {
          this.$refs.create_asset.load(item.id, this.project_tags);
        }
        else {
          this.$refs.create_asset.load(item.id, item.tags);
        }
      }
      else {
        this.$refs.create_asset.load();
      }
    },
    bulkRemoveSelectedAssets(ids) {
      this.selected_assets = this.selected_assets.filter(id => !ids.includes(id))
    },
    async getBooks() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/documentation/books")
          .then((response) => {
            this.loading = false;
            this.books = response.data;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    async getAssetPage() {
      this.selected_book = null;
      this.selected_chapter = null;
      this.selected_page = null;
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/documentation/get-asset-page", { asset_id: this.active_asset_id })
          .then((response) => {
            this.loading = false;
            if(response.data && response.data.book_id) {
              for (let i = 0; i < this.books.length; i++) {
                if(response.data.book_id === this.books[i].id) {
                  this.selected_book = this.books[i];
                  break;
                }
              }
              if(this.selected_book) {
                for (let i = 0; i < this.selected_book.content.length; i++) {
                  if(response.data.chapter_id === this.selected_book.content[i].id) {
                    this.selected_chapter = this.selected_book.content[i];
                    break;
                  }
                }
              }
              if(this.selected_chapter && this.selected_chapter.type === "chapter") {
                for (let i = 0; i < this.selected_chapter.pages.length; i++) {
                  if(response.data.page_id === this.selected_chapter.pages[i].id) {
                    this.selected_page = this.selected_chapter.pages[i];
                    break;
                  }
                }
              }
            }
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    async savePage() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/documentation/save-page", {
            asset_id: this.active_asset_id,
            book_id: this.selected_book ? this.selected_book.id : null,
            chapter_id: this.selected_chapter ? this.selected_chapter.id : null,
            page_id: this.selected_page ? this.selected_page.id : null,
          })
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    viewDocumentation() {
      this.$refs.documentation.load();
    },
  },
  watch: {
    tags: {
      handler() {
        if (this.tags && this.platform_type === 'all-platforms') {
          helpFunctions.setLocalStorageValue(this.module_name + "_tags", this.tags);
        }
        this.showRecords();
      },
    },
    selected_assets: {
      handler(newV, oldV) {
        let status = newV.length > oldV.length, difference = newV.filter(x => !oldV.includes(x)).concat(oldV.filter(x => !newV.includes(x)));
        if(status) {
          if(difference.length > 0) {
            difference.forEach(assetId => {
              if(this.canEdit(assetId)) {
                this.selected_assets_move.push(assetId);
              }
              if(this.canDelete(assetId)) {
                this.selected_assets_delete.push(assetId);
              }
            })
            if(this.canCreate()) {
              this.selected_assets_copy.push(...difference);
            }
          }
        } else {
          this.selected_assets_move = this.selected_assets_move.filter(asset_id => !difference.includes(asset_id));
          this.selected_assets_copy = this.selected_assets_copy.filter(asset_id => !difference.includes(asset_id));
          this.selected_assets_delete = this.selected_assets_delete.filter(asset_id => !difference.includes(asset_id));
        }
      },
    },
    active_assets: {
      handler() {
        if (this.active_assets && this.active_assets[0] !== 0) {
          if(this.platform_type === 'all-platforms') {
            helpFunctions.setLocalStorageValue(this.module_name + "_active_assets", this.active_assets);
          }
          if (this.active_assets.length > 0 && this.active_assets[0] !== this.active_asset_id) {
            this.active_asset_id = this.active_assets[0];
            this.getAssetInfo();
          }
        }
      },
    },
    open_assets: {
      handler() {
        if (this.open_assets && this.platform_type === 'all-platforms') {
          helpFunctions.setLocalStorageValue(this.module_name + "_open_assets", this.open_assets);
        }
      },
    },
    selected_platforms: {
      handler() {
        if (this.platform_type === 'all-platforms') {
          helpFunctions.setLocalStorageValue(this.module_name + "_selected_platforms", this.selected_platforms);
        }
        this.load();
      },
    },
  },
};
</script>

<style>
.v-treeview--dense .v-treeview-node__root {
  min-height: 30px;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky !important;
  top: 85px !important;
}

.v-icon--disabled{
  display: none !important;
}
</style>